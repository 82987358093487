import { generateStoryBoard } from "../../../Services/StoryboardService/StoryBoardService";

export type handleStoryboardGenerationType = {
  description: string;
  promptStyle: { prompt: string };
  cameraOption: string;
  cameraSettings: string;
  projectId: string;
  sequenceId: string;
  breakdownId: string;
  singleSelectedShot: any;
};

export async function handleStoryboardGeneration({
  promptStyle,
  cameraOption,
  cameraSettings,
  projectId,
  sequenceId,
  breakdownId,
  singleSelectedShot,
  description
}: handleStoryboardGenerationType) {
  let requestPayload: any = {};

  function replaceCharacterWithAppearance(
    characterAppearance: any,
    description: string
  ) {
    for (let character in characterAppearance) {
      if (characterAppearance.hasOwnProperty(character)) {
        const appearance = characterAppearance[character];
        description = description.replaceAll(character, `(${appearance})`);
      }
    }
    return description;
  }

  requestPayload.projectId = projectId;
  requestPayload.sequenceId = sequenceId;
  requestPayload.breakdownId = breakdownId;
  requestPayload.shot_list = [];
  const currentShot: any = singleSelectedShot;
  let cameraPrompt = cameraOption
    ? `${cameraOption} ${cameraSettings}`
    : currentShot.cameraPosition;
  let shotDescription = currentShot.description;

  if (currentShot.shotMeta?.characterAppearance) {
    shotDescription = replaceCharacterWithAppearance(
      currentShot.shotMeta?.characterAppearance,
      currentShot.description
    );
  }

  currentShot.Midjourney_Prompt =
    `Style:(${promptStyle.prompt}) ${shotDescription} Important Note:${description} Camera:(((${cameraPrompt}: 1.2)))` +
    `Location background description:(${currentShot.Midjourney_Prompt})`;
  currentShot.userDescription = `${cameraPrompt} ${description}`;
  requestPayload.shot_list.push({
    Midjourney_Prompt: currentShot.Midjourney_Prompt,
    projectId: currentShot.projectId,
    shotId: currentShot.shotId,
    sequenceId: currentShot.sequenceId,
    breakdownId: currentShot.breakdownId,
  });

  try {
    await generateStoryBoard(requestPayload);
  } catch (error: any) {
    if (error?.response?.data?.errCode === "CREDINSF") {
      throw new Error("Insufficient Credits");
    }
  }
}
